import {Injectable} from '@angular/core';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {filter, first, skip} from "rxjs/operators";
import firebase from "firebase/compat";
import {BehaviorSubject, Observable} from "rxjs";
import {notUndefined} from "../../ultils/functions";
import {PurchaseDoc} from "../models/Trip";
import {NavController} from "@ionic/angular";
import {Paths} from "../models/Enums";
import User = firebase.User;

@Injectable({
  providedIn: 'root'
})
export class AuthPurchaseService {

  private readonly tripStorageKey = 'CACHED_TRIP';

  /**
   * The reference to the purchases collection
   * @private
   */
  private readonly tripsRef = this.firestore.collection<PurchaseDoc>('trips');

  /**
   * Each user is registered with email: <PurchaseID>@purchase.app
   * @private
   */
  private readonly fakeEmailSuffix = '@purchase.app';

  /**
   * Get the purchase data according to the logged-in user
   */
  private readonly _trip$ = new BehaviorSubject<PurchaseDoc | null | undefined>(undefined);
  public readonly trip$: Observable<PurchaseDoc | null> = this._trip$.pipe(
    filter(notUndefined)
  );

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private navCtrl: NavController,
  ) {
    this.auth.user.subscribe(async user => {
      let trip: PurchaseDoc | undefined;
      if (user) {
        try {
          trip = await this.getUserPurchase(user);
          // If there is no trip for that user, delete the user & logout
          if (!trip) {
            user.delete();
            this.logout();
          }
        } catch {
          // In case of error while trying to load the document, try to get it from cache
          trip = this.getPurchaseFromCache();
        }
        localStorage.setItem(this.tripStorageKey, JSON.stringify(trip));
      } else {
        this.navCtrl.navigateRoot(Paths.LOGIN);
      }
      this._trip$.next(trip ?? null);
    });
  }

  /**
   * Login user according to the purchase ID. Create the user in case the user was not created yet.
   * Throw an error if the purchase was not found after login
   * @param purchaseId
   */
  public async loginWithPurchase(purchaseId: string) {
    const fakeEmail = purchaseId + this.fakeEmailSuffix;
    try {
      await this.auth.signInWithEmailAndPassword(fakeEmail, fakeEmail);
    } catch {
      await this.auth.createUserWithEmailAndPassword(fakeEmail, fakeEmail);
    }
    const purchase = await this.trip$.pipe(
      skip(1),
      first()
    ).toPromise();
    if (!purchase) {
      throw new Error('Purchase does not exist');
    }
  }

  /**
   * Logout and clear the storage
   */
  public logout() {
    localStorage.removeItem(this.tripStorageKey);
    return this.auth.signOut();
  }

  /**
   * Get the purchase document that belongs to the user, or undefined, if not found.
   * @param user
   * @private
   */
  private async getUserPurchase(user: User): Promise<PurchaseDoc | undefined> {
    const purchaseId = user.email?.endsWith(this.fakeEmailSuffix) && parseInt(user.email).toString();
    if (purchaseId) {
      const snapshot = await this.tripsRef.doc(purchaseId).get().toPromise();
      return snapshot.data();
    }
    return;
  }

  /**
   * Get the last purchase from the local storage
   * @private
   */
  private getPurchaseFromCache(): PurchaseDoc | undefined {
    try {
      const cache = localStorage.getItem(this.tripStorageKey);
      return cache ? JSON.parse(cache) as PurchaseDoc : undefined;
    } catch {
      return;
    }
  }

}
