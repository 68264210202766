import {Pipe, PipeTransform} from '@angular/core';
import {formatDate} from "@angular/common";
import {addDays} from "../../ultils/functions";
import {Platform} from "@ionic/angular";

@Pipe({
  name: 'tripDate',
})
export class TripDatePipe implements PipeTransform {

  constructor(private platform: Platform) {
  }

  /**
   * Show a date in the required format.
   * If a day offset was supplied, show the date of X days ahead.
   * If another day offset was supplied show both dates as a dates range
   * @param value The base date value
   * @param dayOffset Days to count after the base date
   * @param toIdx Days to count after the base date for the second date
   */
  transform(value?: string | number | Date, dayOffset: number = 0, toIdx? : number): string {
    if (typeof value !== "undefined") {
      const d = addDays(value, dayOffset);
      const from = formatDate(d, 'd/M/yyyy', 'he');
      if (toIdx) {
        const to = this.transform(value, toIdx);
        const range = this.platform.isRTL ? [to, from] : [from, to];
        return range.join(' - ');
      }
      return from;
    }
    return '';
  }

}
