import { Injectable } from '@angular/core';
import {LoadingController, AlertController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
  ) { }

  async promptError(errorMsg: string, details: string = '') {
    const a = await this.alertCtrl.create({
      header: 'שגיאה',
      subHeader: errorMsg,
      message: details,
      buttons: ['סגירה'],
      cssClass: 'overlay-error-alert',
    });
    await a.present();
    await a.onDidDismiss();
  }

  async withLoader<T>(promise: Promise<T>, message: string): Promise<T> {
    const loader = await this.loadingCtrl.create({message});
    await loader.present();
    return promise.finally(() => {
      loader.dismiss();
    });
  }

}
