import {Directive, HostListener, Input} from '@angular/core';
import {GetMyLocation$} from '../../ultils/location';
import {AlertsService} from '../../core/services/alerts.service';
import {ICoords} from '../../core/models/Place';
import { Browser } from '@capacitor/browser';

@Directive({
  selector: '[navTo]'
})
export class NavToDirective {

  /**
   * The destination to nav from the user's current location
   */
  @Input('navTo') destination?: {coords: ICoords} | ICoords;

  /**
   * Whether walking / driving mode
   */
  @Input() isWalking: boolean = false;

  /**
   * When clicking on the element, open navigation - only if the user is within the map's bounds
   */
  @HostListener('click')
  navTo() {
    if (this.destination) {
      GetMyLocation$.subscribe(r => {
        if (1 || r) {
          this.openLink();
        } else {
          this.alerts.promptError('מיקומך מחוץ לגבולות המפה');
        }
      });
    }
  }

  constructor(
    private alerts: AlertsService,
  ) {
  }

  /**
   * Open the URL for navigating to the given destination.
   * https://developers.google.com/maps/documentation/urls/get-started
   * Use in app browser
   */
  openLink() {
    if (!this.destination) {
      return;
    }
    const coords = 'coords' in this.destination ? this.destination.coords : this.destination;
    const latLngUrl = new google.maps.LatLng(coords).toUrlValue();
    const url = new URL('https://www.google.com/maps/dir/?api=1');
    url.searchParams.set('destination', latLngUrl);
    url.searchParams.set('travelmode', this.isWalking ? 'walking' : 'driving');
    if (!this.isWalking) {
      url.searchParams.set('layer', 'traffic');
    }
    this.openNav(url.href);
  }

  async openNav(url: string): Promise<void> {
    window.open(url, '_system');
  }
}
