import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {Paths} from './core/models/Enums';
import {PurchaseGuard} from './core/guards/purchase.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: Paths.MAP,
    pathMatch: 'full'
  },
  {
    path: Paths.LOGIN,
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canActivate: [PurchaseGuard],
  },
  {
    path: Paths.MAP,
    loadChildren: () => import('./map/map.module').then(m => m.MapPageModule),
    canActivate: [PurchaseGuard],
  },
  {
    path: Paths.CAR,
    loadChildren: () => import('./my-car/my-car.module').then(m => m.MyCarPageModule)
  },
  {
    path: Paths.HOTELS,
    loadChildren: () => import('./my-hotels/my-hotels.module').then(m => m.MyHotelsPageModule)
  },
  {
    path: Paths.ACTIVITIES,
    loadChildren: () => import('./my-activities/my-activities.module').then(m => m.MyActivitiesPageModule)
  },
  {
    path: Paths.INFO,
    loadChildren: () => import('./information/information.module').then(m => m.InformationPageModule)
  },
  {
    path: Paths.EQUIPMENT,
    loadChildren: () => import('./equipment/equipment.module').then(m => m.EquipmentModule)
  },
  {
    path: Paths.EMERGENCY,
    loadChildren: () => import('./emergency/emergency.module').then(m => m.EmergencyPageModule)
  },
  {
    path: Paths.NORTHEN_LIGHTS,
    loadChildren: () => import('./northen-lights/northen-lights.module').then(m => m.NorthenLightsPageModule)
  },
  {
    path: Paths.PDF,
    loadChildren: () => import('./pdf/pdf.module').then(m => m.PdfModule),
  },
  {
    path: 'northen-lights',
    loadChildren: () => import('./northen-lights/northen-lights.module').then( m => m.NorthenLightsPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
