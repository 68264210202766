import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthPurchaseService} from "../services/auth-purchase.service";
import {map} from "rxjs/operators";
import {Paths} from "../models/Enums";

@Injectable({
  providedIn: 'root'
})
export class PurchaseGuard implements CanActivate {

  private readonly loginPath = Paths.LOGIN;
  private readonly mainPath = Paths.MAP;

  constructor(
    private authService: AuthPurchaseService,
    private router: Router,
  ) {
  }

  /**
   * If the user tries to go to the login page, allow him only if he has no purchase, otherwise, redirect him to the app.
   * If the user tries to go to the app, allow him only if he has a purchase, otherwise, redirect him to the login page.
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.trip$.pipe(
      map(purchase => {
        if (route.routeConfig?.path === this.loginPath) {
          return !purchase || this.router.createUrlTree([this.mainPath]);
        } else {
          return !!purchase || this.router.createUrlTree([this.loginPath]);
        }
      })
    )
  }

}
