<div #drawer class="drawer">
  <div class="drawer-top">
    <div class="drawer-handle" *ngIf="!isFixed"></div>
    <ng-content select="[top]"></ng-content>
  </div>
  <div class="drawer-content"
       [ngStyle]="{maxHeight: allowTop ? 'none' : height + 'px'}"
  >
    <ng-content></ng-content>
  </div>
</div>
