<ion-header>
  <ion-toolbar color="light">
    <ion-title>{{title}}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="close()">
        <ion-icon slot="icon-only" name="close-circle-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="place-details" [ngStyle]="{'background-image': place.image?.url ? 'url(' + place.image?.url + ')' : '' }">
    <div class="place-details-content" *ngIf="place.type === '' || place.type === 'activity'">
      <h1 class="typ-title">{{place.name}}</h1>
      <ul>
        <li *ngIf="place.level && place.type === ''">
          <ion-icon name="walk"></ion-icon>
          <span>רמת קושי: {{place.level | level}}</span>
        </li>
        <li *ngIf="place.is4x4 && place.type === ''">
          <ion-icon name="car"></ion-icon>
          <span>נגיש לרכב: {{place.is4x4 ? '4X4' : 'רגיל'}}</span>
        </li>
        <li *ngIf="place.duration">
          <ion-icon name="pin"></ion-icon>
          <span>פרק זמן להקדיש למקום: {{place.duration}}</span>
        </li>
        <li *ngIf="place.minAge && place.type === 'activity'">
          <ion-icon name="people"></ion-icon>
          <span>גיל {{place.minAge}}+</span>
        </li>

      </ul>
    </div>


  </div>

  <main>
    <section class="icons" *ngIf="place.type !== '' && place.type !== 'activity' && (place.type !== 'hotel' && place.type !== 'restaurant')">
      <div class="item" *ngIf="place.duration">
        <ion-icon name="time" color="secondary"></ion-icon>
        <h6 class="typ-label">זמן מוערך</h6>
        <span>{{place.duration}}</span>
      </div>
      <div class="item" *ngIf="place.level">
        <ion-icon name="walk" color="secondary"></ion-icon>
        <h6 class="typ-label">רמת קושי</h6>
        <span>{{place.level | level}}</span>
      </div>
      <div class="item" *ngIf="place.address">
        <ion-icon name="business" color="secondary"></ion-icon>
        <h6 class="typ-label">כתובת</h6>
        <span>{{place.address}}</span>
      </div>
      <div class="item" *ngIf="place.phone">
        <ion-icon name="call" color="secondary"></ion-icon>
        <h6 class="typ-label">מספר טלפון</h6>
        <a [href]="'tel:' + place.phone" dir="ltr">{{place.phone}}</a>
      </div>
      <div class="item" *ngIf="url">
        <ion-icon name="link" color="secondary"></ion-icon>
        <h6 class="typ-label">קישור</h6>
        <a [href]="url" target="_blank">לחץ כאן</a>
      </div>
      <div class="item" *ngIf="place.minAge">
        <ion-icon name="people" color="secondary"></ion-icon>
        <h6 class="typ-label">גיל</h6>
        <span>{{place.minAge}}+</span>
      </div>
      <div class="item" *ngIf="place.is4x4">
        <ion-icon name="cog" color="secondary"></ion-icon>
        <h6 class="typ-label">גישה ל-4X4 בלבד</h6>
      </div>
    </section>

    <h1 class="typ-title" *ngIf="place.type !== '' && place.type !== 'activity'">{{place.name}}</h1>

    <section class="hotel" *ngIf="place.type === 'hotel' || place.type === 'restaurant'">
      <div class="item phone light-background" *ngIf="place.phone">
      <ion-icon name="phone-portrait-outline" color="secondary"></ion-icon>
        <a [href]="'tel:' + place.phone" dir="ltr">{{place.phone}}</a>
      </div>
      <div class="item" *ngIf="url">
        <ion-icon name="home-outline" color="secondary"></ion-icon>
        <span>{{place.address}}</span>
      </div>
      <div class="item" *ngIf="place.is4x4">
        <ion-icon name="cog" color="secondary"></ion-icon>
        <h6 class="typ-label">גישה ל-4X4 בלבד</h6>
      </div>
    </section>

    <div [innerHTML]="place.shortDescription | safeHtml"></div>

    <section *ngIf="place.hikingPoints?.length" class="hiking-points">
      <h2 class="typ-normal light-background">מסלולי הליכה מומלצים</h2>
      <div *ngFor="let hiking of place.hikingPoints" class="hp-item-wrapper">
        <h3 class="typ-title">{{hiking.name}}</h3>
        <div class="icons">
          <div class="item" *ngIf="hiking.distance">
            <ion-icon name="navigate" color="secondary"></ion-icon>
            <span>{{hiking.distance}}</span>
          </div>
          <div class="item" *ngIf="hiking.duration">
            <ion-icon name="time" color="secondary"></ion-icon>
            <span>{{hiking.duration}}</span>
          </div>
          <div class="item" *ngIf="hiking.level">
            <ion-icon name="walk" color="secondary"></ion-icon>
            <span>{{hiking.level | level}}</span>
          </div>
        </div>
        <div [innerHTML]="hiking.description | safeHtml"></div>
        <ion-button [navTo]="hiking" [isWalking]="true"
                    color="secondary" fill="clear"  class="ion-margin-vertical"
        >
          <span>קחו אותי לתחילת המסלול הרגלי &#187;</span>
          <ion-icon slot="start" name="walk"></ion-icon>
        </ion-button>
      </div>
    </section>

    <section class="ion-padding-vertical">
      <div [innerHtml]="place.description | safeHtml"></div>
    </section>

  </main>

</ion-content>

<ion-footer class="ion-padding">
  <app-large-button color="secondary"
                    icon="pin"
                    [alignCenter]="true"
                    [navTo]="place"
  >קחו אותי לשם
  </app-large-button>
  <app-large-button (click)="close()" *ngIf="url && (place.type === 'hotel' || place.type === 'restaurant')"
                    color="primary"
                    icon="globe-outline"
                    [href]="url"
  >אתר האינטרנט של {{place.type === 'hotel' ? 'המלון' : 'המסעדה'}}
  </app-large-button>
</ion-footer>
