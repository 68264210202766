import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { addIcons } from 'ionicons';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

addIcons({
  'sign-up-arrow': 'assets/icons/left_arrow.svg',
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
