import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Paths} from "../../../core/models/Enums";

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {

  @Input() fullTripButton: boolean = false;

  @Input() title: string = '';

  @Output() fullTrip = new EventEmitter<void>();


  defaultHref = Paths.MAP;

  constructor() {
  }

  ngOnInit() {
  }

}
