import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {environment} from '../environments/environment';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireModule} from '@angular/fire/compat';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {OverlaysModule} from './overlays/overlays.module';
import localeHe from '@angular/common/locales/he';
import {registerLocaleData} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {SideMenuComponent} from './core/components/side-menu/side-menu.component';
import {HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {TripService} from './core/services/trip.service';
import {MockTripService} from './core/services/mock-trip.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';

// Register hebrew
registerLocaleData(localeHe);

@NgModule({
  declarations: [
    AppComponent,
    SideMenuComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    provideAuth(() => getAuth()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    OverlaysModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: LOCALE_ID, useValue: 'he'},
    {
      provide: TripService,
      useClass: environment.mockPlaces ? MockTripService : TripService
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
