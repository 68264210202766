<ion-header>
  <ion-toolbar color="primary">
    <ion-title>תפריט ראשי</ion-title>
    <ion-buttons slot="end">
      <ion-menu-toggle>
        <ion-button>
          <ion-icon name="close" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-menu-toggle>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="lists">
    <ion-menu-toggle [autoHide]="false">
      <ion-list>
        <ion-item button [routerLink]="Paths.MAP" routerDirection="forward">
          <ion-icon slot="start" name="compass"></ion-icon>
          מסלול הטיול שלי
        </ion-item>
<!--        <ion-item button [routerLink]="Paths.HOTELS" routerDirection="forward">-->
<!--          <ion-icon slot="start" name="bed"></ion-icon>-->
<!--          מקומות הלינה שלי לטיול-->
<!--        </ion-item>-->
<!--        <ion-item button [routerLink]="Paths.CAR" routerDirection="forward">-->
<!--          <ion-icon slot="start" [src]="menu_logos.car"></ion-icon>-->
<!--          הרכב שלי לטיול-->
<!--        </ion-item>-->
<!--        <ion-item button [routerLink]="Paths.ACTIVITIES" routerDirection="forward">-->
<!--          <ion-icon slot="start" [src]="menu_logos.activity"></ion-icon>-->
<!--          פעילויות מוזמנות-->
<!--        </ion-item>-->
<!--        <ion-item button [routerLink]="Paths.PDF">-->
<!--          <ion-icon slot="start" [src]="menu_logos.pdf"></ion-icon>-->
<!--          תכנית הטיול שלי ב-PDF-->
<!--        </ion-item>-->
        <ion-item button [routerLink]="Paths.INFO" routerDirection="forward">
          <ion-icon slot="start" [src]="menu_logos.info"></ion-icon>
          מידע חשוב
        </ion-item>
<!--              <ion-item button [routerLink]="Paths.EMERGENCY" routerDirection="forward" >-->
<!--          <ion-icon slot="start" [src]="menu_logos.emergency"></ion-icon>-->
<!--          מספרי טלפון וחירום-->
<!--        </ion-item>-->
<!--        <ion-item button [routerLink]="Paths.NORTHEN_LIGHTS" routerDirection="forward" >-->
<!--          <ion-icon slot="start" [src]="menu_logos.northen"></ion-icon>-->
<!--          מדריך אורות הצפון-->
<!--        </ion-item>-->
        <ion-item button [routerLink]="Paths.EQUIPMENT" routerDirection="forward" >
          <ion-icon slot="start" [src]="menu_logos.equipment"></ion-icon>
          רשימת ציוד מומלצת לטיול
        </ion-item>
<!--        <ion-item button (click)="pwa.installPwa()" *ngIf="pwa.canInstallPwa">-->
<!--          <ion-icon slot="start" [src]="menu_logos.add_pwa"></ion-icon>-->
<!--          הוספה למסך הבית-->
<!--        </ion-item>-->
      </ion-list>
    </ion-menu-toggle>
    <ion-list>
      <ion-item>
        <ion-label color="medium">v. {{version}}</ion-label>
      </ion-item>
      <ion-menu-toggle [autoHide]="false">
        <ion-item button lines="none" (click)="logout()">
          <ion-icon name="log-out" color="danger" slot="start"></ion-icon>
          יציאה
        </ion-item>
      </ion-menu-toggle>
    </ion-list>
  </div>
</ion-content>
