import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {Directory, Filesystem} from '@capacitor/filesystem';

@Directive({
  selector: 'img'
})
export class CacheImageDirective implements OnInit {

  /**
   * Main directory for saving the cached images
   * @private
   */
  private static readonly CACHE_DIR = 'AnotherIcelandCache';

  /**
   * Placeholder image while reading the image or if there is no image
   * @private
   */
  private static readonly PLACEHOLDER = 'assets/images/no-image.png';

  /**
   * The image src
   */
  get src() {
    return this.el.nativeElement.src;
  }

  /**
   * Set the image src
   * @param src
   */
  @Input() set src(src: string | undefined) {
    this.el.nativeElement.src = CacheImageDirective.PLACEHOLDER;
    src && this.setSrc(src).then(r => {
      r && (this.el.nativeElement.src = r);
    });
  }

  constructor(
    private el: ElementRef<HTMLImageElement>
  ) {
  }

  ngOnInit() {
  }

  /**
   * Read the file from cache, save it if not exist
   * @param src
   * @private
   */
  private async setSrc(src: string) {
    try {
      if (this.isWebImage(src)) {
        return await this.readFile(src);
      }
      return src;
    } catch (e) {
      this.saveFile(src);
      return src;
    }
  }

  /**
   * Check whether the path is a web url that should be cached
   * @param imageUrl
   * @private
   */
  private isWebImage(imageUrl: string) {
    try {
      const url = new URL(imageUrl);
      return url.protocol.startsWith('http');
    } catch {
      return false;
    }
  }

  /**
   * Get the image cache path according to its web URL
   * @param imageUrl
   * @private
   */
  private getImagePath(imageUrl: string) {
    const path = new URL(imageUrl).pathname.split('/').pop() as string;
    const innerPath = decodeURIComponent(path);
    return `${CacheImageDirective.CACHE_DIR}/${innerPath}`;
  }

  /**
   * Read file from cache as a base64
   * @param imageUrl
   * @private
   */
  private async readFile(imageUrl: string) {
    const path = this.getImagePath(imageUrl);
    const fileType = path.split('.').pop();
    const r = await Filesystem.readFile({
      path,
      directory: Directory.Cache,
    });
    return `data:image/${fileType};base64,${r.data}`;
  };

  /**
   * Save file in cache as base64 string
   * @param imageUrl
   * @private
   */
  private async saveFile(imageUrl: string) {
    const path = this.getImagePath(imageUrl);
    await this.createDir(path);
    await Filesystem.writeFile({
      path,
      directory: Directory.Cache,
      data: await this.toBase64(imageUrl),
    });
  };

  /**
   * Create the missing path directories if needed
   * @param imageUrl
   * @private
   */
  private async createDir(imageUrl: string) {
    const fullPath = imageUrl.split('/').slice(0, -1);
    for (let i = 0; i < fullPath.length; i++) {
      const path = fullPath.slice(0, i + 1).join('/');
      try {
        await Filesystem.readdir({
          directory: Directory.Cache,
          path
        })
      } catch {
        await Filesystem.mkdir({
          directory: Directory.Cache,
          path
        })
      }
    }
  }

  /**
   * Get the URL blob and return its base64
   * @param imageUrl
   * @private
   */
  private async toBase64(imageUrl: string): Promise<string> {
    const r = await fetch(imageUrl);
    const blob = await r.blob();
    return new Promise<string>((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => resolve(fr.result as string);
      fr.onerror = e => reject(e);
      fr.readAsDataURL(blob);
    });
  }

}
