// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Dev environment: Iceland's map with fake self location
 */
export const environment = {
  firebase: {
    projectId: 'north-greece-app',
    appId: '1:404779732759:web:f7d4a42b580f07a50e7152',
    storageBucket: 'north-greece-app.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyBQAIhgNJYy_scfVf-m8Z5Rlhf3sT0pbmg',
    authDomain: 'north-greece-app.firebaseapp.com',
    messagingSenderId: '404779732759',
    measurementId: 'G-ZLR7L0KJDN',
  },
  googleMapKey: 'AIzaSyCqPgfAA_smWJhOQlXkzM-0-htz650l5vg',
  mockLocation: null,
  mockPlaces: false,
  mapBounds: {
    west: 20.0,
    east: 28.3,
    north: 41.75,
    south: 34.8
  },
  production: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
