import {Component, OnInit} from '@angular/core';
import {AuthPurchaseService} from "../../../core/services/auth-purchase.service";
import {Paths} from "../../../core/models/Enums";
import {PwaService} from "../../services/pwa.service";

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  Paths = Paths;

  readonly version = require('package.json').version;

  menu_logos = {
    compass: 'assets/icons/compass.svg',
    car: 'assets/icons/car_sm2.svg',
    activity: 'assets/icons/event_sm.svg',
    pdf: 'assets/icons/file_dock.svg',
    info: 'assets/icons/info_sm.svg',
    emergency: 'assets/icons/emrgncy_no.svg',
    northen: 'assets/icons/fire_light.svg',
    equipment: 'assets/icons/iconoir_notes.svg',
    add_pwa: 'assets/icons/add_pwa_sm.svg'

  }

  constructor(
    private authService: AuthPurchaseService,
    public pwa: PwaService,
  ) { }

  ngOnInit() {}

  logout() {
    this.authService.logout();
  }

}
