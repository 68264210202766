import {PlaceDoc} from './Place';
import {CarPicking, PlaceType} from './Enums';
import {addDays, parseTime} from '../../ultils/functions';

/**
 * The trip (purchase) data as it is saved in the DB
 */
export interface PurchaseDoc {
  id: string;
  name: string;
  points: TripPoint[];
  comments: DayComments;
  startDate?: string;
  car?: Car[] | Car;
  travelers?: string;
  startEndAirport?: string;
}

export interface MultipleTripPoint extends TripPoint {
  pointId?: string
}

/**
 * Every point in the trip includes the place ID + properties relevant for the specific trip (for now, only time)
 */
export interface TripPoint {
  id: string;
  time?: string;
  booking?: string;
  multiple?: boolean;
  multipleItems?: string[];
  hotel?: {
    room: string;
    info: string;
  };
}

/**
 * The place's data + the data for the specific trip
 */
export type TripPlace = PlaceDoc & TripPoint;

/**
 * List of comments for every day
 */
export type DayComments = {
  [day: number]: string[];
};

/**
 * A day in the trip includes list of places + comments for the day
 */
export interface TripDay {
  places: TripPlace[];
  comments: string[];
}

export interface Car {
  model: CarModel;
  company: CarCompany;
  booking: string;
  fromTo: {
    place: CarPicking;
    date: string;
  }[];
}

export interface CarModel {
  name: string;
  image?: string;
}

export interface CarCompany {
  name: string;

  tel?: string;
  data?: { [P in CarPicking]: CarCompanyData };
}

export interface CarCompanyData {
  pick: {
    location?: google.maps.LatLngLiteral;
    text: string;
  };
  return: {
    location?: google.maps.LatLngLiteral;
    text: string;
  };
}

/**
 * The trip instance is the main object which includes all the trip's data that was collected from the DB
 */
export class Trip {

  readonly id: string;
  readonly name: string;
  readonly days: TripDay[];
  readonly car: Car[];
  readonly travelers?: string;
  readonly startDate?: string;

  constructor(purchaseDoc: PurchaseDoc, points: PlaceDoc[], airport: PlaceDoc, airportEnd: PlaceDoc) {
    this.id = purchaseDoc.id;
    this.name = purchaseDoc.name;
    this.travelers = purchaseDoc.travelers;
    this.startDate = purchaseDoc.startDate;
    this.days = this.buildDays(points, purchaseDoc, airport, airportEnd);
    this.car = purchaseDoc.car ? (Array.isArray(purchaseDoc.car) ? purchaseDoc.car : [purchaseDoc.car]) : [];
  }

  /**
   * Build the trip instance according to the given doc + the given loaded places:
   * Split points into days, when every day ends after a hotel.
   * Add the comments to each day
   * Add the airport at the beginning and the end of the trip
   *
   * @private
   */
  private buildDays(places: PlaceDoc[], doc: PurchaseDoc, airport: PlaceDoc, airportEnd: PlaceDoc): TripDay[] {
    if (airport) {
      airport.type = PlaceType.Airport;
    }
    if (airportEnd) {
      airportEnd.type = PlaceType.Airport;
    }
    places.unshift(airport);
    places.push(airportEnd);
    const days: TripDay[] = [];
    let day = 0;
    places.forEach(place => {
      if (!days[day]) {
        days.push({
          places: [],
          comments: doc.comments[day],
        });
      }
      const moreInfo = doc.points.find(p => p.id === place?.id);
      const time = moreInfo?.time ? this.toTripTime(moreInfo.time, day).toJSON() : undefined;
      days[day].places.push({
        ...place,
        time,
        booking: moreInfo?.booking,
        hotel: moreInfo?.hotel,
      });
      if (place?.type === PlaceType.Hotel) {
        day++;
      }
    });
    return days;
  }

  private isValidDay(day: number): boolean {
    return day >= 0 && day < this.days.length;
  }

  /**
   * Get time as string (hh:mm) and the day index of the trip and return the Date object of this time
   *
   * @param time
   * @param day
   * @private
   */
  private toTripTime(time: string, day: number): Date {
    const startDate = this.startDate ?? Date.now();
    const date = addDays(startDate, day);
    return parseTime(time, date);
  }

  /**
   * Get all the places of the given day, including the last point of the previous day
   *
   * @param day
   */
  getDayPlaces(day: number): TripPlace[] {
    if (this.isValidDay(day)) {
      const dayPoints = this.days[day].places.slice();
      if (day > 0) {
        const lastHotel = this.days[day - 1].places.slice().pop();
        dayPoints.unshift(lastHotel!);
      }
      return dayPoints;
    }
    throw new Error('Invalid day index');
  }

  /**
   * Get all the trip's places as one array
   */
  getAllPlaces(): TripPlace[] {
    return ([] as TripPlace[]).concat(...this.days.map(d => d.places));
  }

  /**
   * Get the day index of the trip according to current date.
   * (Including before and after the trip dates).
   * If start date is not set, return NaN
   */
  currentDay(): number {
    if (this.startDate) {
      const dayPeriod = 24 * 60 * 60 * 1000;
      const gap = Date.now() - +new Date(this.startDate);
      return Math.floor(gap / dayPeriod);
    }
    return NaN;
  }

  /**
   * Whether the current date is during the trip
   */
  isAtTrip(): boolean {
    const currentDay = this.currentDay();
    return this.isValidDay(currentDay);
  }

}


