import {Injectable} from '@angular/core';
import {AuthPurchaseService} from './auth-purchase.service';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {catchError, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {MultipleTripPoint, PurchaseDoc, Trip} from '../models/Trip';
import {combineLatest, Observable, of} from 'rxjs';
import {PlaceDoc} from '../models/Place';
import {PwaService} from './pwa.service';
import {PlaceType} from '../models/Enums';

@Injectable()
export class TripService {

  /**
   * The current trip object, based on the current purchase (only after maps API loaded)
   */
  public readonly trip$: Observable<Trip | null> = this.authService.trip$.pipe(
    switchMap((purchase) => purchase
      ? this.buildTrip(purchase)
      : of(null)
    ),
    tap(trip => {
      if (trip) {
        this.pwa.promptInitialMessage();
      }
    }),
    shareReplay(),
  );

  constructor(
    private authService: AuthPurchaseService,
    private firestore: AngularFirestore,
    private pwa: PwaService,
  ) {
  }

  /**
   * Load point according to type & ID
   *
   * @private
   * @param id
   */
  protected loadPlace(id: string) {
    const ref = this.firestore.collection<PlaceDoc>('places').doc(id);
    return ref.get().pipe(
      map(doc => doc.data()),
      catchError((e) => {
        console.error(e);
        return of(undefined);
      })
    );
  }

  protected loadMultiplePlaces(point: MultipleTripPoint) {
    if (!point.pointId) {
      return of({...point, type: PlaceType.Hotel} as PlaceDoc);
    }
    const ref = this.firestore.collection<PlaceDoc>('places').doc(point.id);
    return ref.get().pipe(
      map(doc => ({
          ...doc.data(),
          pointId: point.pointId
        } as PlaceDoc )),
      catchError((e) => {
        console.error(e);
        return of(undefined);
      })
    );
  }

  protected loadAirport(id?: string) {
    let idPlace;
    if (id === 'Athens Airport' || id === 'Athens - Thessaloniki') {
      idPlace = '92';
    }else if (id === 'Thessaloniki Airport' || id === 'Thessaloniki - Athens') {
      idPlace = '93';
    }
    const ref = this.firestore.collection<PlaceDoc>('places').doc(idPlace);
    return ref.get().pipe(
      map(doc => doc.data()),
      catchError((e) => {
        console.error(e);
        return of(undefined);
      })
    );
  }

  protected loadAirportEnd(id?: string) {
    let idPlace;
    if (id === 'Athens Airport' || id === 'Thessaloniki - Athens') {
      idPlace = '92';
    }else if (id === 'Thessaloniki Airport' || id === 'Athens - Thessaloniki') {
      idPlace = '93';
    }
    const ref = this.firestore.collection<PlaceDoc>('places').doc(idPlace);
    return ref.get().pipe(
      map(doc => doc.data()),
      catchError((e) => {
        console.error(e);
        return of(undefined);
      })
    );
  }

  private addFetchedPlacesToMultiplePlaces(list: (PlaceDoc | undefined)[]) {
    list.forEach((l) => {
      if (l?.pointId) {
        const index = list.findIndex((f) => f?.id === l.pointId);
        const multiplePoint = list[index];
        if (multiplePoint) {
          multiplePoint.multipleItems = [...multiplePoint.multipleItems, l];
        }
      }
    });
    return list;
  }

  private prepareFetchPointsWithMultiplePlaces(trip: PurchaseDoc) {
    const points: MultipleTripPoint[] = [...trip.points];
     trip.points.forEach((p, i) => {
      if (p.multiple) {
        p.multipleItems?.forEach((mp) => {
          points.push({multiple: true, id: mp , pointId: p.id});
        });
        p.multipleItems = [];
      }});
     return points;
  }

  /**
   * Load all trip points + airport, and build the trip object
   *
   * @param trip
   * @private
   */
  private buildTrip(trip: PurchaseDoc): Observable<Trip> {
    const points = this.prepareFetchPointsWithMultiplePlaces(trip);
    return combineLatest([
      this.loadAirport(trip.startEndAirport),
      this.loadAirportEnd(trip.startEndAirport),
      ...points.map(p => p.multiple ? this.loadMultiplePlaces(p) : this.loadPlace(p.id))
    ]).pipe(
      map(([airport, airportEnd , ...list]) => {
        const listOfPlaces = this.addFetchedPlacesToMultiplePlaces(list);
        const places = listOfPlaces?.filter((p, i) => {
          if (p?.pointId) {
            return;
          }
          if (!p) {
            console.warn(`Place ${trip.points[i]} was not found!`);
          }
          return !!p;
        }) as PlaceDoc[];
        if (!airport) {
          console.warn('Airport was not found');
        }
        return new Trip(trip, places, airport!, airportEnd!);
      }),
    );
  }

}
