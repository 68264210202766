import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-large-button',
  templateUrl: './large-button.component.html',
  styleUrls: ['./large-button.component.scss'],
})
export class LargeButtonComponent {

  @Input() color = 'primary';

  @Input() textColor = '#fff';

  @Input() size = 'small';

  @Input() fill: 'solid' | 'outline' = 'solid';

  @Input() disabled = false;

  @Input() href?: string;

  @Input() icon?: string;

  @Input() alignCenter = false;

  @Input() iconStart = false;
  @Input() iconSize: 'large' | 'default' = 'large';

  @Input() fontSizeRem: 'rem-85' | 'rem-7' | 'px-32' = 'rem-85';

  @Input() semiBoldText = false;

}

