import {Component, HostListener} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {Paths} from './core/models/Enums';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  /**
   * Hide the menu on login page
   */
  disabledMenu$ = this.router.events.pipe(
    filter(ev => ev instanceof NavigationEnd),
    map(ev => (ev as NavigationEnd).url.startsWith('/' + Paths.LOGIN))
  );

  constructor(
    private router: Router
  ) {
  }

}
