<ion-button expand="block"
            shape="round"
            [size]="size"
            strong="false"
            class="button"
            [fill]="fill"
            [color]="color"
            [disabled]="disabled"
            [href]="href"
            target="_blank"
            [class.outline]="fill === 'outline'"
            [class.large]="size === 'large'"
>
  <div class="button-content" [ngStyle]="{color: textColor}">
    <ion-icon [name]="icon"
              *ngIf="icon && iconStart"
              slot="start"
              [size]="iconSize"
    ></ion-icon>
    <ion-icon class="start" size="large" *ngIf="alignCenter"></ion-icon>
    <span [ngClass]="fontSizeRem" [class.semi-bold-text]="semiBoldText">
      <ng-content></ng-content>
    </span>
    <ion-icon [name]="icon"
              *ngIf="icon && !iconStart"
              slot="end"
              [size]="iconSize"
    ></ion-icon>
  </div>

</ion-button>
