<ion-app>

  <ion-split-pane contentId="content" when="xl">

    <ion-menu side="start"
              type="overlay"
              [swipeGesture]="false"
              contentId="content"
              [disabled]="!!(disabledMenu$ | async)"
    >
      <app-side-menu></app-side-menu>
    </ion-menu>

    <ion-router-outlet id="content"></ion-router-outlet>

  </ion-split-pane>

</ion-app>
