import {inject, Injectable} from '@angular/core';
import {TripService} from "./trip.service";
import {map, switchMap} from "rxjs/operators";
import {PlaceDoc} from "../models/Place";
import {MapGeocoder} from "@angular/google-maps";
import {environment} from "../../../environments/environment";
import {from, Observable, of} from "rxjs";

@Injectable()
export class MockTripService extends TripService {

  private geocoder = inject(MapGeocoder);

  protected override loadPlace(id: string) {
    return super.loadPlace(id).pipe(
      switchMap(doc => this.mockPlace(doc))
    )
  }

  /**
   * Replace the place's coords to coords of some random settlement in Israel
   * @param place
   * @private
   */
  private mockPlace(place?: PlaceDoc): Observable<PlaceDoc | undefined> {
    if (place) {
      return from(
        fetch('https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=1300')
      ).pipe(
        switchMap(r => r.json()),
        map(r => r.result.records.map((p: any) => p['שם_ישוב'])),
        switchMap(list => {
          place.address = list[Math.floor(Math.random() * list.length)];
          return this.geocoder.geocode({
            address: place.address,
            bounds: environment.mapBounds,
            componentRestrictions: {
              country: 'IL',
            }
          })
        }),
        map(res => {
          const position = res.results[0]?.geometry.location;
          if (position) {
            place.coords = position.toJSON();
          } else {
            place.coords = {
              lat: 32.8070295,
              lng: 35.7381887,
            };
          }
          return place;
        })
      )
    } else {
      return of(undefined);
    }
  }

}
