import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TripPlace} from '../../../core/models/Trip';

@Component({
  selector: 'app-place-details',
  templateUrl: './place-details.component.html',
  styleUrls: ['./place-details.component.scss'],
})
export class PlaceDetailsComponent implements OnInit {

  @Input() place!: TripPlace;

  @Input() index!: number;

  @Input() startDate?: string;

  @Input() selectedDay?: number;

  @Input() showAddress = false;

  @Input() multiple = false;

  @Output() read = new EventEmitter<TripPlace>();

  constructor() { }

  ngOnInit() {
  }


  protected readonly innerWidth = innerWidth;
}
