import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {TripPlace} from '../../core/models/Trip';

@Component({
  selector: 'app-place-modal',
  templateUrl: './place-modal.component.html',
  styleUrls: ['./place-modal.component.scss'],
})
export class PlaceModalComponent implements OnInit {

  @Input() place!: TripPlace;

  @Input() from?: string;

  @Input() to?: string;

  title = '';
  url: string | undefined;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    if (this.place?.type === 'hotel' && this.from && this.to && this.place?.url) {
      if  (this.place.url.includes('?')) {
        this.url = `${this.place.url}&group_adults=2&group_children=0&checkin=${this.from}&checkout=${this.to}`;
      }else {
        this.url = `${this.place.url}?group_adults=2&group_children=0&checkin=${this.from}&checkout=${this.to}`;
      }
    }else {
      this.url = this?.place?.url;
    }
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
