export function notUndefined<T>(value: T | undefined): value is T {
  return typeof value !== "undefined";
}

export function notNullish<T>(value: T | null | undefined): value is T {
  return (value ?? null) !== null;
}

export function sumArray(ar: number[]) {
  return ar.reduce((p, c) => p + c, 0);
}

export function addDays(date: string | number | Date, days: number) {
  const d = new Date(date);
  d.setDate(d.getDate() + days);
  return d;
}

export function parseTime(time: string, date?: number | string | Date) {
  const parts = time.split(':').map(p => +p).filter(Boolean);
  const d = new Date(date ?? Date.now());
  d.setHours(parts[0] || 0, parts[1] || 0, parts[2] || 0);
  return d;
}
