<ion-toolbar>
  <div class="toolbar-wrapper">
    <ion-buttons slot="start">

      <ion-menu-toggle>
        <ion-button *ngIf="!fullTripButton"
                    size="large"
                    color="primary"
        >
          <ion-icon name="toolbar-menu" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-menu-toggle>

      <ion-button *ngIf="fullTripButton"
                  color="primary"
                  (click)="fullTrip.emit()"
      >
        <ion-icon name="chevron-back" slot="start"></ion-icon>
        <span>חזרה למסלול מלא</span>
      </ion-button>

    </ion-buttons>
    <ion-title *ngIf="title !== ''">{{title}}</ion-title>
    <img src="assets/logo/logo-toolbar.png">
  </div>
</ion-toolbar>
