export enum Paths {
  LOGIN = 'login',
  MAP = 'map',
  CAR = 'my-car',
  HOTELS = 'my-hotels',
  ACTIVITIES = 'my-activities',
  INFO = 'information',
  EQUIPMENT = 'equipment',
  EMERGENCY = 'emergency',
  NORTHEN_LIGHTS = 'northen-lights',
  PDF = 'pdf',
}

export enum PlaceType {
  Place = '',
  Hotel = 'hotel',
  Restaurant = 'restaurant',
  Activity = 'activity',
  Airport = 'airport',
}

export enum CarPicking {
  Airport = 'airport',
  City = 'city',
  Hotel = 'hotel',
  Harbour = 'harbour',
}
