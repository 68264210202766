import {Observable} from 'rxjs';
import {first, map, shareReplay, timeout} from 'rxjs/operators';
import {environment} from '../../environments/environment';

/**
 * Observe current location (or use mock)
 * Return null if out of map bounds
 */
export const MyLocation$: Observable<google.maps.LatLngLiteral | null> =
  new Observable<google.maps.LatLngLiteral>(subscriber => {
    const watch = navigator.geolocation.watchPosition(res => {
      subscriber.next(environment.mockLocation || {
        lat: res.coords.latitude,
        lng: res.coords.longitude
      });
    });
    subscriber.add(() => navigator.geolocation.clearWatch(watch));
  }).pipe(
    map(coords => {
      const bounds = new google.maps.LatLngBounds().union(environment.mapBounds);
      return bounds.contains(coords) ? coords : null;
    }),
    shareReplay()
  );

/**
 * Emit once with the current location. Throw an error after timeout
 */
export const GetMyLocation$ = MyLocation$.pipe(
  first(),
  timeout(100000),
);
