import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PwaService {

  private readonly LS_PWA_RESP_KEY = 'pwa_response';

  private deferredPrompt: any | null;

  /**
   * Whether it's possible to prompt the PWA installation
   */
  public get canInstallPwa() {
    return !!this.deferredPrompt;
  }

  constructor() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
    });
  }

  /**
   * Prompt the PWA installation
   */
  public installPwa(): Promise<string> {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();
      return this.deferredPrompt.userChoice; // "accepted" or "dismissed"
    }
    throw new Error('Cannot install PWA');
  }

  /**
   * Prompt the PWA installation, if possible and there is no user response yet, and save the user response
   */
  public async promptInitialMessage() {
    if (this.canInstallPwa && !localStorage.getItem(this.LS_PWA_RESP_KEY)) {
      const resp = await this.installPwa();
      localStorage.setItem(this.LS_PWA_RESP_KEY, resp);
    }
  }

}
