import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {IonicModule} from '@ionic/angular';
import {BottomSwiperComponent} from './components/bottom-swiper/bottom-swiper.component';
import {LargeButtonComponent} from './components/large-button/large-button.component';
import {TripDatePipe} from './pipes/trip-date.pipe';
import {LevelPipe} from './pipes/level.pipe';
import {PlaceDetailsComponent} from './components/place-details/place-details.component';
import {GetDownloadURLPipeModule} from '@angular/fire/compat/storage';
import {NavToDirective} from './directives/nav-to.directive';
import {CacheImageDirective} from './directives/cache-image.directive';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {BookingUrlPipe} from "../map/pipes/booking-url.pipe";


@NgModule({
  declarations: [
    ToolbarComponent,
    BottomSwiperComponent,
    LargeButtonComponent,
    TripDatePipe,
    LevelPipe,
    SafeHtmlPipe,
    PlaceDetailsComponent,
    NavToDirective,
    CacheImageDirective,
    BookingUrlPipe,
  ],
  exports: [
    IonicModule,
    GetDownloadURLPipeModule,
    ToolbarComponent,
    BottomSwiperComponent,
    LargeButtonComponent,
    TripDatePipe,
    LevelPipe,
    SafeHtmlPipe,
    BookingUrlPipe,
    PlaceDetailsComponent,
    NavToDirective,
    CacheImageDirective,
  ],
  imports: [
    CommonModule,
    IonicModule,
    GetDownloadURLPipeModule,
  ]
})
export class SharedModule {
}
