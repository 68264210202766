import {Pipe, PipeTransform} from '@angular/core';
import {addDays} from '../../ultils/functions';
import {formatDate} from '@angular/common';


@Pipe({
  name: 'bookingUrl'
})
export class BookingUrlPipe implements PipeTransform {


  transform(value?: string, selectedDate?: any, startDate?: any): string {
    let from;
    let to;
    if (startDate && typeof selectedDate === 'number') {
      const d = addDays(startDate, selectedDate);
      from = formatDate(d, 'yyyy-MM-dd', 'he');
      const dateTo = addDays(startDate, selectedDate + 1);
      to  = formatDate(dateTo, 'yyyy-MM-dd', 'he');
    }

    if ( from && to && value) {
      if  (value.includes('?')) {
        return `${value}&group_adults=2&group_children=0&checkin=${from}&checkout=${to}`;
      }else {
        return `${value}?group_adults=2&group_children=0&checkin=${from}&checkout=${to}`;
      }
    }else {
      return value || '';
    }
  }

}
