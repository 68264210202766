import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Gesture, GestureController} from '@ionic/angular';

@Component({
  selector: 'app-bottom-swiper',
  templateUrl: './bottom-swiper.component.html',
  styleUrls: ['./bottom-swiper.component.scss'],
})
export class BottomSwiperComponent implements OnInit, OnChanges, AfterViewInit {

  @ViewChild('drawer') drawer?: ElementRef<HTMLElement>;

  @Input() isFixed = false;

  @Input() height = 0;

  @Input() allowTop = false;

  @Input() transition = 500;

  @Output() opened = new EventEmitter<number>();

  private content?: HTMLElement;

  private top?: HTMLElement;

  private toggleState = 0;

  private swipingGesture?: Gesture;

  private currentHeight = 0;

  constructor(
    private gestureCtrl: GestureController,
  ) {
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.setSwiping();
    this.toggle(1);
  }

  ngAfterViewInit() {
    this.content = this.drawer?.nativeElement.querySelector('.drawer-content') as HTMLElement;
    this.top = this.drawer?.nativeElement.querySelector('.drawer-top') as HTMLElement;
    this.setSwiping();
    this.toggle(1);
  }

  /**
   * Set the gesture behavior
   *
   * @private
   */
  private async setSwiping() {
    this.swipingGesture?.destroy();
    const drawer = this.drawer?.nativeElement;
    const handle = drawer?.querySelector('.drawer-top');
    if (!this.content || !handle || this.isFixed) {
      return;
    }
    this.currentHeight = this.content?.clientHeight;
    this.swipingGesture = await this.gestureCtrl.create({
      el: handle,
      gestureName: 'swipe',
      direction: 'y',
      onMove: ev => {
        this.content?.style.setProperty('height', `${this.currentHeight - ev.deltaY}px`);
      },
      onEnd: ev => {
        let toggleTo = this.toggleState;
        if (ev.deltaY < -50) {
          toggleTo < 2 && toggleTo++;
          if (ev.currentY < this.height - 100) {
            toggleTo < 2 && toggleTo++;
          }
        }
        if (ev.deltaY > 50) {
          toggleTo > 0 && toggleTo--;
          if (ev.currentY > this.height + 100) {
            toggleTo > 0 && toggleTo--;
          }
        }
        this.toggle(toggleTo);
      }
    });
    this.swipingGesture.enable(true);
  }

  /**
   * Open / close the panel
   *
   * @param state
   * @private
   */
  private toggle(state: number) {
    this.toggleState = state;
    this.opened.emit(state);
    const topHeight = this.top?.clientHeight;
    const height = {
      0: '0',
      1: this.height + 'px',
      2: `calc(100vh - ${topHeight}px - 170px)`
    }[state];
    if (!height) {
      throw new Error('Invalid state');
    }
    this.content?.style.setProperty('height', height);
    this.content?.style.setProperty('transition', `height ${this.transition}ms`);
    setTimeout(() => {
      this.content!.style.transition = 'none';
      this.currentHeight = this.content!.clientHeight;
    }, this.transition);
  }

}
