<div class="place-view" [ngClass]="{'activity': place.type === 'activity'}">
  <div class="img-wrapper">
    <img [src]="place.image?.url" [alt]="'Image of ' + place.name">
    <div class="order" *ngIf="!!index">{{index}}</div>
    <div class="typ-small" *ngIf="place.time">{{place.time | date : 'HH:mm'}}</div>
  </div>
  <div class="place-view-details">
    <h6>{{place.name}}</h6>
    <div class="multiple-btns" *ngIf="multiple">
      <app-large-button [href]="place?.url | bookingUrl : selectedDay : startDate "  [fontSizeRem]="'rem-7'"  [iconStart]="true" [iconSize]="'default'" [icon]="'alert-circle-outline'" fill="outline" [textColor]="'#005EB3'">לפרטים והזמנה</app-large-button>
      <app-large-button  [navTo]="place" [fontSizeRem]="'rem-7'"  [iconStart]="true" [iconSize]="'default'" [icon]="'location-outline'" [color]="'danger'" fill="outline" [textColor]="'#eb445a'">קחו אותי למלון</app-large-button>
    </div>
    <div class="duration" *ngIf="place.duration">
      <ion-icon name="time" color="secondary" size="small"></ion-icon>
      <span>
        <span class="typ-label" *ngIf="place.type !== 'activity'">מומלץ להקדיש</span>
        <span class="typ-label" *ngIf="place.type === 'activity'">משך פעילות</span>
        <span>&nbsp;{{place.duration}}</span>
      </span>
    </div>
    <h6 class="typ-label-secondary" *ngIf="place.booking">הזמנה: {{place.booking}}</h6>
    <p *ngIf="showAddress">{{place.address}}</p>
    <div *ngIf="place.phone && !multiple" class="phone-wrapper">
      <ion-icon name="phone-portrait-outline" color="primary"></ion-icon>
      <a [href]="'tel:' + place.phone" dir="ltr">{{place.phone}}</a>
    </div>

    <div class="action-btns">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<div *ngIf="place.hotel as hotel">
  <div class="hotel-room" *ngIf="hotel.room">
    <ion-icon name="room" size="large" color="medium" class="ion-margin-end"></ion-icon>
    <span class="typ-label">סוג החדר: </span>
    <span>{{hotel.room}}</span>
  </div>
  <p>{{hotel.info}}</p>
</div>
